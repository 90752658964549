<template>
  <div class="card-content detail-content body-payment">
    <div class="header-pc clearfix">
      <div class="inner">
        <h1>
          <!-- <div>
            <img src="@/assets/img/logo_default.png" alt="" />
          </div> -->
          <a
            :href="
              $router.resolve({
                name: $route.params.shopId ? 'store' : 'store domain',
              }).href
            "
          >
            <div class="logo">
              <img
                v-if="logoShop"
                :src="logoShop"
                width="100%"
                height="100%"
                style="
                  object-fit: contain;
                  width: 200px;
                  height: 50px;
                  object-position: left;
                "
              />
            </div>
          </a>
        </h1>
        <p class="step" style="position: absolute; right: 0; top: 50%">
          <img src="@/assets/img/img_step02.png" alt="STEP2" />
        </p>
      </div>
    </div>
    <div class="dc-header mb-4 position-relative">
      <b-container fluid class="d-flex align-items-center font-title">
        <router-link
          :to="{ name: $route.params.shopId ? 'store' : 'store domain' }"
        >
          <div>ショップ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link :to="{ name: '' }">
          <div>購入手続き</div>
        </router-link>
      </b-container>
      <p class="bread-drum">
        <img src="../../assets/img/img_step02.png" />
      </p>
    </div>
    <div id="purchase">
      <div class="floatingWrap">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="ttl01">
              <span>ご購入手続きへ進む</span>
            </h1>
          </div>
        </div>
        <div class="row info-order">
          <div class="w-100">
            <section class="procedureContent">
              <div class="inner" v-if="userInput">
                <div class="inputArea">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">
                          メールアドレスのご確認（※確認のみお願いします。再入力の必要はありません）
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 nameWrap">
                          <input
                            type="text"
                            name="email"
                            class="formParts form-field customer_input_area"
                            placeholder="メールアドレス"
                            v-model="infoBuyer.email"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.email"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.EMAILNULL }}
                        </p>
                        <p
                          v-if="infoBuyer.emailInvalid"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.EMAIL_INVALID }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">顧客タイプ</div>
                      </div>
                      <div class="companyType d-flex">
                        <div class="d-flex">
                          <input
                            type="radio"
                            v-model="infoBuyer.company_type"
                            value="0"
                            class="radio_type"
                            id="radio1"
                          />
                          <label for="radio1">
                            <span>個人</span>
                          </label>
                        </div>
                        <div class="d-flex">
                          <input
                            type="radio"
                            v-model="infoBuyer.company_type"
                            value="1"
                            class="radio_type"
                            id="radio2"
                          />
                          <label for="radio2">
                            <span>法人</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">会社名</div>
                      </div>
                      <div class="row">
                        <div class="col-12 nameWrap">
                          <input
                            type="text"
                            name="email"
                            class="formParts form-field customer_input_area"
                            v-model="infoBuyer.company_name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">お名前</div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="lastName"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="姓"
                            v-model="infoBuyer.lastName"
                          />
                        </div>
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="firstName"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="名"
                            v-model="infoBuyer.firstName"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.firstName || !infoBuyer.lastName"
                          class="erroTxt erroTxt-fullname"
                        >
                          お名前を入力してください。
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">お名前カナ</div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="lastNameKana"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="セイ"
                            v-model="infoBuyer.lastNameKana"
                          />
                        </div>
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="firstNameKana"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="メイ"
                            v-model="infoBuyer.firstNameKana"
                          />
                        </div>
                        <p
                          v-if="
                            !infoBuyer.firstNameKana ||
                            !infoBuyer.lastNameKana ||
                            !infoBuyer.firstNameKana.match(isKana) ||
                            !infoBuyer.lastNameKana.match(isKana)
                          "
                          class="erroTxt erroTxt-fullnameKana"
                        >
                          お名前をカタカタで入力してください。
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">郵便番号（半角数字）</div>
                      </div>
                      <div style="display: flex">
                        <div class="col-lg-6 nameWrap">
                          <input
                            class="formParts form-field customer_input_area"
                            v-model="infoBuyer.zipCodeOne"
                            v-on:blur="
                              getAddress(
                                `${infoBuyer.zipCodeOne}${infoBuyer.zipCodeTwo}`
                              )
                            "
                            onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                            maxLength="3"
                            :isValid="infoBuyer.zipCodeInvalid"
                            :invalidFeedback="infoBuyer.messZipCodeInvalid"
                          />
                        </div>
                        <span style="align-self: center">-</span>
                        <div class="col-lg-6 nameWrap">
                          <input
                            v-model="infoBuyer.zipCodeTwo"
                            v-on:blur="
                              getAddress(
                                `${infoBuyer.zipCodeOne}${infoBuyer.zipCodeTwo}`
                              )
                            "
                            onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                            maxLength="4"
                            :isValid="infoBuyer.zipCodeInvalid"
                            :invalidFeedback="infoBuyer.messZipCodeInvalid"
                            class="formParts form-field customer_input_area"
                          />
                        </div>
                      </div>
                      <p
                        v-if="!infoBuyer.zipCodeTwo || !infoBuyer.zipCodeOne"
                        class="erroTxt erroTxt-fullname"
                      >
                        {{ feedback.POSTALCODE_NULL }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">都道府県</div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 nameWrap">
                          <input
                            type="text"
                            name="email"
                            class="formParts form-field customer_input_area"
                            placeholder=""
                            v-model="infoBuyer.province"
                            :disabled="true"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.province"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.PROVINCE_NULL }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">市区町村番地</div>
                      </div>
                      <div class="row">
                        <div class="col-12 nameWrap">
                          <input
                            type="text"
                            name="email"
                            class="formParts form-field customer_input_area"
                            placeholder="市区町村番地を入力"
                            v-model="infoBuyer.address"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.address"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.ADDRESS_NULL }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">ビル・マンション名</div>
                      </div>
                      <div class="row">
                        <div class="col-12 nameWrap">
                          <input
                            type="text"
                            name="email"
                            class="formParts form-field customer_input_area"
                            placeholder=""
                            v-model="infoBuyer.building_room"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">
                          電話番号（半角数字）
                          <span class="necessary"></span>
                        </div>
                      </div>
                      <div style="display: flex">
                        <div
                          class="col-lg-4 nameWrap"
                          style="padding-left: 0 !important"
                        >
                          <input
                            class="formParts form-field customer_input_area"
                            v-model="infoBuyer.phone1"
                            onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                            maxLength="3"
                            :isValid="infoBuyer.phoneInvalid1"
                            :invalidFeedback="infoBuyer.messPhoneInvalid1"
                            placeholder="06"
                          />
                        </div>
                        <span style="align-self: center">-</span>
                        <div class="col-lg-4 nameWrap">
                          <input
                            v-model="infoBuyer.phone2"
                            onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                            maxLength="4"
                            :isValid="infoBuyer.phoneInvalid2"
                            :invalidFeedback="infoBuyer.messPhoneInvalid2"
                            placeholder="1234"
                            class="formParts form-field customer_input_area"
                          />
                        </div>
                        <span style="align-self: center">-</span>
                        <div class="col-lg-4 nameWrap">
                          <input
                            v-model="infoBuyer.phone3"
                            onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57"
                            maxLength="4"
                            :isValid="infoBuyer.phoneInvalid3"
                            :invalidFeedback="infoBuyer.messPhoneInvalid3"
                            placeholder="5678"
                            class="formParts form-field customer_input_area"
                          />
                        </div>
                      </div>
                      <p>（固定電話または携帯電話のいずれか）</p>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="userInput === listUserInput.name_email_phone_address"
                  >
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">
                          住所（全角）
                          <span class="necessary"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 nameWrap">
                          <input
                            type="text"
                            name="address"
                            class="formParts form-field customer_input_area"
                            placeholder="住所"
                            v-model="infoBuyer.address"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.address"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.REQUIRED }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inner" v-if="!userInput">
                <div class="inputArea">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">
                          お名前（全角）
                          <span class="necessary"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="lastName"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="姓"
                            v-model="infoBuyer.lastName"
                          />
                        </div>
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="firstName"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="名"
                            v-model="infoBuyer.firstName"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.firstName || !infoBuyer.lastName"
                          class="erroTxt erroTxt-fullname"
                        >
                          お名前を入力してください。
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">
                          お名前カナ（全角）
                          <span class="necessary"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="lastNameKana"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="セイ"
                            v-model="infoBuyer.lastNameKana"
                          />
                        </div>
                        <div class="col-lg-6 nameWrap">
                          <input
                            type="text"
                            name="firstNameKana"
                            class="formParts form-field customer_input_area"
                            maxlength="20"
                            placeholder="メイ"
                            v-model="infoBuyer.firstNameKana"
                          />
                        </div>
                        <p
                          v-if="
                            !infoBuyer.firstNameKana ||
                            !infoBuyer.lastNameKana ||
                            !infoBuyer.firstNameKana.match(isKana) ||
                            !infoBuyer.lastNameKana.match(isKana)
                          "
                          class="erroTxt erroTxt-fullnameKana"
                        >
                          お名前をカタカタで入力してください。
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">メールアドレス</div>
                      </div>
                      <div class="row">
                        <div class="col-12 nameWrap">
                          <input
                            type="text"
                            name="email"
                            class="formParts form-field customer_input_area"
                            placeholder="メールアドレス"
                            v-model="infoBuyer.email"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.email"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.EMAILNULL }}
                        </p>
                        <p
                          v-if="infoBuyer.emailInvalid"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.EMAIL_INVALID }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    v-if="userInput === listUserInput.name_email_phone_address"
                  >
                    <div class="col-lg-12">
                      <div class="row" style="margin-top: 15px">
                        <div class="col-lg-12">
                          住所（全角）
                          <span class="necessary"></span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 nameWrap">
                          <input
                            type="text"
                            name="address"
                            class="formParts form-field customer_input_area"
                            placeholder="住所"
                            v-model="infoBuyer.address"
                          />
                        </div>
                        <p
                          v-if="!infoBuyer.address"
                          class="erroTxt erroTxt-fullname"
                        >
                          {{ feedback.REQUIRED }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="paymentContent">
              <div class="row">
                <div class="col-lg-12">
                  <h1 class="ttl01">
                    <span>お支払い方法を選択</span>
                  </h1>
                </div>
              </div>
              <div class="row" v-if="listContent.length > 0">
                <div class="col-lg-12">
                  <div class="inner" style="padding: unset !important">
                    <ul class="tabBtn" style="padding: unset !important">
                      <li v-for="(value, index) in listPayment" :key="index">
                        <input
                          type="radio"
                          name="payment"
                          class="formParts form-field"
                          :id="
                            value == 0
                              ? 'stripe'
                              : value == 1
                              ? 'paypal'
                              : 'transfer'
                          "
                          :value="value"
                          @change="paymentMethod(value)"
                        />
                        <label
                          :for="
                            value == 0
                              ? 'stripe'
                              : value == 1
                              ? 'paypal'
                              : 'transfer'
                          "
                        >
                          <span>{{
                            value == 0
                              ? "クレジットカード"
                              : value == 1
                              ? "paypal"
                              : "銀行振込"
                          }}</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-12" v-if="payment.method == 0">
                  <b-form-group>
                    <template>
                      <div>
                        <!-- <stripe-element-card
                          ref="elementRef"
                          :pk="publishableKey"
                          :hidePostalCode="true"
                          @token="tokenCreated"
                          :testMode="true"
                        /> -->
                        <stripe-element-card
                          ref="elementRef"
                          :pk="publishableKey"
                          :hidePostalCode="true"
                          @token="tokenCreated"
                          :testMode="false"
                        />
                      </div>
                      <p>クレジットカードは上記枠にご入力下さい。</p>
                    </template>
                  </b-form-group>
                </div>
              </div>
            </section>
            <div class="row pc-only">
              <p class="btnSubmit">
                <button
                  :disabled="
                    !infoBuyer.firstName ||
                    !infoBuyer.lastName ||
                    !infoBuyer.firstNameKana ||
                    !infoBuyer.lastNameKana ||
                    !infoBuyer.email ||
                    ((userInput === listUserInput.name_email_phone ||
                      userInput === listUserInput.name_email_phone_address) &&
                      (!infoBuyer.phone1 ||
                        !infoBuyer.phone2 ||
                        !infoBuyer.phone3)) ||
                    (userInput === listUserInput.name_email_phone_address &&
                      !infoBuyer.address) ||
                    (!payment.method && payment.method != 0)
                  "
                  @click="createToken()"
                  class="formParts btn01"
                >
                  ご注文確認画面へ進む
                </button>
                <button
                  :disabled="
                    !infoBuyer.firstName ||
                    !infoBuyer.lastName ||
                    !infoBuyer.firstNameKana ||
                    !infoBuyer.lastNameKana
                  "
                  @click="createToken()"
                  class="submit-form-purchase hide"
                >
                  Submit
                </button>
              </p>
              <p class="text-btn-purchase">
                ・転売など当社が不都合と判断した場合はご購入をお断りさせていただくことがございます。
                <br />
                予めご了承ください。
              </p>
              <p class="text-btn-purchase purchase-policy">
                ・購入を完了することにより、これらの
                <a
                  href="javascript:void(0)"
                  @click="$bvModal.show('modal-terms')"
                  >サービス規約</a
                >
                に同意したものと見なされます。
              </p>
              <span id="assentOrderFormProduct"></span>
            </div>
          </div>
        </div>
        <div class="row detail-order">
          <div class="w-100">
            <div class="floatingContent">
              <div class="floatingContentInner">
                <div class="floatingBox">
                  <div class="row">
                    <div class="col-lg-12 titleFloatingBox">
                      <h2>お申込内容</h2>
                    </div>
                  </div>
                  <div class="floatingBoxInner">
                    <span id="amountContents">
                      <div class="row">
                        <div class="col-lg-12">
                          <div
                            class="boxWrap"
                            style="padding: 10px 10px 0"
                            v-for="(value, index) in listContent"
                            :key="index"
                          >
                            <div style="font-weight: bold; font-size: 16px">
                              {{ value.title || value.content_title }}
                            </div>
                            <div
                              style="
                                color: #900;
                                font-weight: bold;
                                font-size: 16px;
                                text-align: right;
                              "
                            >
                              <span v-if="infoSubription.isSubscription">
                                ¥{{
                                  Math.round(Number(totalPrice)).toLocaleString(
                                    "ja"
                                  )
                                }}円 / {{ infoSubription.addBill
                                }}{{ infoSubription.textChange }}（税抜）
                              </span>
                              <span v-else>
                                ¥{{
                                  Math.round(Number(totalPrice)).toLocaleString(
                                    "ja"
                                  )
                                }}（税抜）
                              </span>
                            </div>
                            <div
                              v-if="infoSubription.explan"
                              style="
                                font-weight: bold;
                                font-size: 14px;
                                border-top: 1px solid #d8d8cb;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                padding-top: 5px;
                              "
                            >
                              <pre>{{ infoSubription.explan }}</pre>
                            </div>
                            <div style="clear: both; height: 0"></div>
                            <div class="sub">
                              <dl>
                                <dt>送料・手数料</dt>
                                <dd>
                                  {{
                                    Math.round(Number(fee)).toLocaleString(
                                      "ja"
                                    )
                                  }}円
                                  <span class="excludingTax">（税抜）</span>
                                </dd>
                              </dl>
                              <dl>
                                <dt>消費税</dt>
                                <dd>
                                  {{
                                    Math.round(Number(tax)).toLocaleString(
                                      "ja"
                                    )
                                  }}円
                                </dd>
                              </dl>
                              <dl
                                v-if="
                                  infoSubription.isSubscription &&
                                  infoSubription.trialPeriod > 0
                                "
                              >
                                <dt>お試し期間</dt>
                                <dd>{{ infoSubription.trialPeriod }}日間</dd>
                              </dl>
                              <dl
                                v-if="
                                  infoSubription.isSubscription &&
                                  infoSubription.billingCount > 0
                                "
                              >
                                <dt>請求回数</dt>
                                <dd>{{ infoSubription.billingCount }}回</dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div col-lg-12>
                          <div class="total">
                            <div class="clearfix">
                              <dt>合計</dt>
                              <dd v-if="totalPricePayment">
                                <span
                                  v-if="infoSubription.isSubscription"
                                  class="total-price"
                                  >{{
                                    Math.round(
                                      Number(totalPricePayment)
                                    ).toLocaleString("ja")
                                  }}円 / {{ infoSubription.addBill
                                  }}{{ infoSubription.textChange }}（税抜）
                                </span>
                                <span v-else class="total-price"
                                  >{{
                                    Math.round(
                                      Number(totalPricePayment)
                                    ).toLocaleString("ja")
                                  }}円 （税込）</span
                                >
                              </dd>
                            </div>
                            <div
                              class="clearfix"
                              v-if="
                                infoSubription.isSubscription &&
                                infoSubription.initialCost
                              "
                            >
                              <dt>初期費用</dt>
                              <dd>
                                <span class="total-price">{{
                                  Number(
                                    infoSubription.initialCost * 1
                                  ).toLocaleString("ja")
                                }}</span
                                >円
                                <span class="excludingTax">（税込）</span>
                              </dd>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <p class="notes">
                            分割決済の場合、分割手数料が別途かかります。
                            分割手数料はカード会社によって異なります。
                          </p>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row sp-only">
          <p class="btnSubmit">
            <button
              :disabled="
                !infoBuyer.firstName ||
                !infoBuyer.lastName ||
                !infoBuyer.firstNameKana ||
                !infoBuyer.lastNameKana ||
                !infoBuyer.email ||
                ((userInput === listUserInput.name_email_phone ||
                  userInput === listUserInput.name_email_phone_address) &&
                  !infoBuyer.phone) ||
                (userInput === listUserInput.name_email_phone_address &&
                  !infoBuyer.address) ||
                (!payment.method && payment.method != 0)
              "
              @click="createToken()"
              class="formParts btn01"
            >
              ご注文確認画面へ進む
            </button>
            <button
              :disabled="
                !infoBuyer.firstName ||
                !infoBuyer.lastName ||
                !infoBuyer.firstNameKana ||
                !infoBuyer.lastNameKana
              "
              @click="createToken()"
              class="submit-form-purchase hide"
            >
              Submit
            </button>
          </p>
          <p class="text-btn-purchase">
            ・転売など当社が不都合と判断した場合はご購入をお断りさせていただくことがございます。
            <br />
            予めご了承ください。
          </p>
          <p class="text-btn-purchase purchase-policy">
            ・購入を完了することにより、これらの
            <a href="javascript:void(0)" @click="$bvModal.show('modal-terms')"
              >サービス規約</a
            >
            に同意したものと見なされます。
          </p>
          <span id="assentOrderFormProduct"></span>
        </div>
        <div class="row policyContent">
          <div class="row">
            <div class="col-lg-8 col-sm-12"></div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="modal-terms" hide-header hide-footer :size="'xl'">
        <div class="d-flex justify-content-between">
          <h5>サービス規約</h5>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="$bvModal.hide('modal-terms')"
          >
            ×
          </button>
        </div>
        <hr />
        <div v-html="terms"></div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import paypal from "@/assets/img/paypal.svg";
import { FeedBack } from "@/utils/feedback.js";
import moment from "moment";
import no_image from "@/assets/img/logo_default.png";
import { Routes } from "../../utils/routes";

export default {
  components: {
    StripeElementCard,
  },
  name: "payment",
  data() {
    // this.publishableKey = process.env.VUE_APP_STRIPE;
    // publishableKey = "";
    return {
      publishableKey: "",
      feedback: FeedBack,
      shopId: localStorage.getItem(Constants.SHOP_ID),
      id: this.$route.params.id,
      info: localStorage.getItem(Constants.TOKEN_USER)
        ? JSON.parse(localStorage.getItem(Constants.NORMAL_USER_INFO))
        : localStorage.getItem(Constants.TOKEN_ADMIN)
        ? JSON.parse(localStorage.getItem(Constants.ADMIN_USER_INFO))
        : "",
      infoBuyer: {
        name: "",
        nameKana: "",
        // postalCode: "",
        address: "",
        email: "",
        emailRequired: false,
        emailInvalid: false,
        firstName: "",
        lastName: "",
        firstNameKana: "",
        lastNameKana: "",
        phone: "",
        postal_code: "",
        province: "",
        building_room: "",
        zipCodeOne: "",
        zipCodeTwo: "",
        zipCodeInvalid: "",
        messZipCodeInvalid: "",
        phone1: "",
        phone2: "",
        phone3: "",
        company_type: 0,
        company_name: "",
      },
      fee: 0,
      tax: 0,
      totalOrder: 0,
      totalPrice: 0,
      totalPointUse: 0,
      totalPricePayment: "",
      totalPointBonus: 0,
      rule: "・転売など当社が不都合と判断した場合はご購入をお断りさせていただくことがございます。\n予めご了承ください。\n・購入を完了することにより、これらのサービス規約に同意したものと見なされます。\n",
      payment: {
        method: null,
        number: "",
        interval: "",
        intervalCount: null,
      },
      numPoint: 0,
      paymentType: Constants.PAYMENT_TYPE,
      optionsInterval: Constants.OPTION_INTERVAL,
      optionsPaymentMethod: [],
      listContent: [],
      saveCard: false,
      token: null,
      cardId: null,
      cardType: null,
      cardNumber: null,
      cardExpirationDate: null,
      maxPoint: 0,
      loading: false,
      totalPointOfUser: 0,
      listContentId: [],
      paypal: paypal,
      loadingPaypal: false,
      loadingTransfer: false,
      isToken:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      text_change: "",
      textIntervalCount: "",
      saleMethod: Constants.CONTENT_TYPE,
      terms: "",
      isKana: Constants.REGEX_KANA,
      listPayment: [],
      listUserInput: Constants.USER_INPUT,
      userInput: null,
      infoSubription: {
        isSubscription: false,
        textChange: "",
        initialCost: "",
        trialPeriod: "",
        billInternal: "",
        addBill: "",
        billingCount: "",
        explan: "",
      },
      keyPayPal: "",
      purchaseByUrl: false,
      urlSale: false,
      logoShop: null,
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
      purchase_location_url: null,
    };
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listCart",
      "pointOfUser",
      "detailStore",
      "saleSettingById",
      "authorById",
      "ListSettingShop",
      "refer_url",
    ]),
  },
  async created() {
    this.$store.commit("set", ["modalShow", true]);
    this.$store.commit("set", ["isShowMenu", "none"]);
    this.purchase_location_url = window.document.referrer
      ? window.document.referrer
      : window.location.origin + this.refer_url;
    const { params } = this.$route;
    const { id } = params;
    if (this.is_login) {
      this.getListSettingShopUser({
        domain: Constants.DOMAIN,
        shop_id: parseInt(localStorage.getItem(Constants.SHOP_ID)),
      });
    }
    const req = {
      shopId: this.shopId,
      id: id,
    };
    await this.getInforAuthorByContent(req);
    await this.getSaleSettingUser(this.shopId);

    //end for close modal load
    if (this.$route.name.includes("paymentBuy")) {
      await this.getDetailContentPayment({ shopId: this.shopId, id });
    } else {
      await this.getDetailStore({ shopId: this.shopId, id });
    }
    // if (this.isToken) {
    //   this.getUserUsablePoint(shopId);
    //   this.getListCart({
    //     shop_id: shopId,
    //   });
    //   this.infoBuyer.name = this.info.last_name + " " + this.info.first_name;
    //   this.infoBuyer.postalCode = this.info.postal_code;
    //   this.infoBuyer.address = this.info.address;
    // }
    this.getShopById({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    });
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    detailStore() {
      if (this.detailStore && this.detailStore.is_page404 == true) {
        this.$router.push({
          name: this.$route.params.shopId ? "page404" : "page404 domain",
          params: {
            shopId: this.shop_id,
          },
        });
      }
      if (
        this.detailStore &&
        this.detailStore[0] &&
        this.detailStore[0].duplicate &&
        this.detailStore[0].duplicate == true
      ) {
        if (this.detailStore[0].url_redirect) {
          window.location.href = this.detailStore[0].url_redirect;
          return;
        } else {
          const infoContent = {
            id: this.detailStore[0].id,
            title: this.detailStore[0].content_title,
          };
          localStorage.setItem(
            Constants.INFO_CONTENT,
            JSON.stringify(infoContent)
          );
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "page notice",
              params: {
                shopId: this.shop_id,
              },
            });
          } else {
            this.$router.push({
              name: "page notice domain",
            });
          }
        }
      }
      if (this.detailStore && this.detailStore.length > 0) {
        if (this.listContent.length == 0) {
          this.listContent.push(this.detailStore[0]);
        }
      }
      if (this.listContent.length > 0) {
        this.listContent.forEach((element) => {
          if (this.$route.query.info) {
            const info = JSON.parse(this.$route.query.info);
            this.infoBuyer.lastName = info.lastName;
            this.infoBuyer.firstName = info.firstName;
            this.infoBuyer.lastNameKana = info.lastNameKana;
            this.infoBuyer.firstNameKana = info.firstNameKana;
            this.infoBuyer.email = info.email;
            this.infoBuyer.phone = info.phone;
            this.infoBuyer.address = info.address;
            this.infoBuyer.building_room = info.building_room;
            this.infoBuyer.postal_code = info.postal_code;
            this.infoBuyer.province = info.province;
            this.infoBuyer.company_name = info.company_name;
            this.infoBuyer.company_type = info.company_type;
            let tel = info.tel;
            if (info.postal_code && info.postal_code.length > 0) {
              this.infoBuyer.zipCodeOne = info.postal_code.substring(0, 3);
              this.infoBuyer.zipCodeTwo = info.postal_code.substring(3, 7);
              this.getAddress(info.postal_code);
            }
            if (tel) {
              this.infoBuyer.phone1 = tel ? tel.split("-")[0] : "";
              this.infoBuyer.phone2 = tel ? tel.split("-")[1] : "";
              this.infoBuyer.phone3 = tel ? tel.split("-")[2] : "";
            }
          } else {
            this.infoBuyer.firstName = element.info_user.first_name;
            this.infoBuyer.lastName = element.info_user.last_name;
            this.infoBuyer.email = element.info_user.email;
            this.infoBuyer.phone = element.info_user.tel;
            this.infoBuyer.postal_code = element.info_user.postal_code;
            this.infoBuyer.province = element.info_user.province;
            this.infoBuyer.address = element.info_user.address;
            this.infoBuyer.building_room = element.info_user.building_room;
            if (
              element.info_user.postal_code &&
              element.info_user.postal_code.length > 0
            ) {
              this.infoBuyer.zipCodeOne =
                element.info_user.postal_code.substring(0, 3);
              this.infoBuyer.zipCodeTwo =
                element.info_user.postal_code.substring(3, 7);
              this.getAddress(element.info_user.postal_code);
            }
            let tel = element.info_user.tel;
            if (tel) {
              this.infoBuyer.phone1 = tel ? tel.split("-")[0] : "";
              this.infoBuyer.phone2 = tel ? tel.split("-")[1] : "";
              this.infoBuyer.phone3 = tel ? tel.split("-")[2] : "";
            }
          }
          if (element.sale_other.user_input) {
            this.userInput = parseInt(element.sale_other.user_input);
          } else {
            this.userInput = 0;
          }
          if (element.sale_basic.column[0].sale_format == 0) {
            this.listPayment =
              element.sale_basic.column[0].defaultPayment &&
              element.sale_basic.column[0].defaultPayment.length > 0
                ? element.sale_basic.column[0].defaultPayment
                : typeof element.payment_default === "string"
                ? JSON.parse(element.payment_default)
                : element.payment_default;
            this.infoSubription.explan = element.sale_basic.column[0].explan;
          } else if (element.sale_basic.column[0].sale_format == 1) {
            this.listPayment =
              element.sale_basic.column[0].defaultSubscription &&
              element.sale_basic.column[0].defaultSubscription.length > 0
                ? element.sale_basic.column[0].defaultSubscription
                : typeof element.payment_sub_default === "string"
                ? JSON.parse(element.payment_sub_default)
                : element.payment_sub_default;
          }
          element.product_price = element.sale_basic.column[0].product_price;
          if (
            element.sale_basic &&
            parseInt(element.sale_basic.column[0].priceOverTime) > 0 &&
            element.sale_basic.column[0].fromTimeRelease <=
              moment(String(new Date())).format("YYYY-MM-DD HH:mm") &&
            element.sale_basic.column[0].toTimeRelease >=
              moment(String(new Date())).format("YYYY-MM-DD HH:mm")
          ) {
            element.product_price = parseInt(
              element.sale_basic.column[0].priceOverTime
            );
          }
          this.terms = element.terms;
          const tax = element.sale_basic.column[0].tax
            ? element.sale_basic.column[0].tax
            : element.tax_default;
          this.totalPrice = element.product_price / (1 + tax * 0.01);
          this.totalPointBonus += element.point_bonus.total_point_bonus;
          this.payment.interval = element.sale_basic.column[0].billInternal
            ? element.sale_basic.column[0].billInternal * 1
            : 0;
          this.text_change =
            element.change_billing_interval == 1
              ? "日"
              : element.change_billing_interval == 2
              ? "週間"
              : element.change_billing_interval == 3
              ? "ヶ月"
              : "年";
          this.payment.intervalCount = element.sale_basic.column[0].billingCount
            ? element.sale_basic.column[0].billingCount * 1
            : 0;
          this.textIntervalCount =
            element.billing_interval + this.text_change + "に1回請求する";
          this.tax = element.product_price - this.totalPrice;
          this.fee = element.sale_basic.column[0].fee
            ? element.sale_basic.column[0].fee
            : element.consultation_fee_default;
          this.totalPricePayment = element.product_price * 1 + this.fee * 1;
          if (
            element.sale_basic.column[0].sale_format ==
            this.paymentType.subscription
          ) {
            // const bill_internal = element.sale_basic.column[0].billInternal;
            this.infoSubription.isSubscription = true;
            this.infoSubription.initialCost =
              element.sale_basic.column[0].initialCost;
            this.infoSubription.trialPeriod = element.sale_basic.column[0]
              .trialPeriod
              ? element.sale_basic.column[0].trialPeriod
              : 0;
            this.infoSubription.addBill = element.sale_basic.column[0].addBill;
            this.infoSubription.billingCount =
              element.sale_basic.column[0].billingCount;
            this.infoSubription.textChange =
              element.sale_basic.column[0].textChange;
            this.infoSubription.explan = element.sale_basic.column[0].explan;
          }
        });
        this.maxPoint = this.totalPricePayment;
        this.$store.commit("set", ["modalShow", false]);
      }
    },
    authorById() {
      if (this.authorById && this.authorById.stripe_id) {
        this.publishableKey = this.authorById.stripe_id;
      } else {
        const shop_info =
          localStorage.getItem(Constants.DETAIL_SHOP) &&
          JSON.parse(localStorage.getItem(Constants.DETAIL_SHOP));
        this.publishableKey = shop_info.stripe_id;
      }
      if (this.authorById && this.authorById.paypal_id) {
        this.keyPayPal = this.authorById.paypal_id;
      } else {
        const shop_info =
          localStorage.getItem(Constants.DETAIL_SHOP) &&
          JSON.parse(localStorage.getItem(Constants.DETAIL_SHOP));
        this.keyPayPal = shop_info.paypal_id;
      }
    },
    "infoBuyer.email"() {
      this.infoBuyer.emailRequired = false;
      this.infoBuyer.emailInvalid = false;
    },
    "infoBuyer.zipCodeOne"() {
      this.infoBuyer.zipCodeInvalid = null;
      this.infoBuyer.messZipCodeInvalid = "";
    },
    "infoBuyer.zipCodeTwo"() {
      this.infoBuyer.zipCodeInvalid = null;
      this.infoBuyer.messZipCodeInvalid = "";
    },
    ListSettingShop() {
      if (this.ListSettingShop && this.ListSettingShop.length > 0) {
        if (this.ListSettingShop[0].logo != null) {
          this.logoShop =
            process.env.VUE_APP_ROOT_BACKEND +
            "/" +
            this.ListSettingShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      } else {
        this.logoShop = no_image;
      }
    },
  },
  methods: {
    ...mapActions({ getShopById: "getShopById" }),
    ...mapActions({
      getDetailStore: "getDetailStore",
      getListSettingShopUser: "getListSettingShopUser",
      getDetailContentPayment: "getDetailContentPayment",
    }),
    ...mapActions({ getSaleSettingUser: "getSaleSettingUser" }),
    ...mapActions({ getInforAuthorByContent: "getInforAuthorByContent" }),
    moment,

    addPointUse() {
      if (this.numPoint) {
        if (parseInt(this.numPoint) > this.maxPoint) {
          this.$toasted.error(
            "合計注文金額より多くポイントを使用することはできません。"
          );
        } else if (parseInt(this.numPoint) > this.pointOfUser) {
          this.$toasted.error("ポイントが不足しています。");
        } else {
          this.totalPointUse = parseInt(this.numPoint);
          this.totalPricePayment =
            this.totalPrice - parseInt(this.numPoint) + this.tax + this.fee;
        }
      }
    },
    tokenCreated(token) {
      this.token = token.id;
      let card = token.card;
      this.cardId = card.id;
      this.cardType = card.brand;
      this.cardNumber = card.last4;
      this.cardExpirationDate =
        card.exp_year + ("00" + card.exp_month).substr(-2) + "01";
      if (token) {
        this.goConfirm();
      }
    },
    submit() {
      if (this.isToken) {
        this.$refs.elementRef.submit();
      } else {
        this.$bvModal.show("modal-create-user");
      }
    },
    createToken() {
      if (this.payment.method == 0) {
        this.$refs.elementRef.submit();
      } else {
        this.goConfirm();
      }
    },
    goConfirm() {
      let error;
      if (
        !this.infoBuyer.lastNameKana.match(Constants.REGEX_KANA) ||
        !this.infoBuyer.firstNameKana.match(Constants.REGEX_KANA)
      ) {
        this.$toasted.show("お名前をカタカタで入力してください。");
        error = true;
      }

      if (this.userInput) {
        const {
          zipCodeOne,
          zipCodeTwo,
          province,
          address,
          phone1,
          phone2,
          phone3,
        } = this.infoBuyer;
        if (
          !zipCodeOne ||
          !zipCodeTwo ||
          !province ||
          !address ||
          !phone1 ||
          !phone2 ||
          !phone3
        ) {
          this.$toasted.show("必須項目のご入力をお願いします。");
          error = true;
        }
      }
      if (!error) {
        let bank_transfer;
        let purchase_credit_card;
        if (
          this.listContent &&
          this.listContent.length > 0 &&
          this.listContent[0].sale_basic.column &&
          this.listContent[0].sale_basic.column[0].typeUrlPageThank == 1 &&
          this.listContent[0].sale_basic.column[0].listSlagSetting &&
          this.listContent[0].sale_basic.column[0].listSlagSetting.length > 0 &&
          this.listContent[0].info_user.slag &&
          this.listContent[0].info_user.slag.length > 0
        ) {
          this.listContent[0].sale_basic.column[0].listSlagSetting.forEach(
            (element) => {
              if (
                this.listContent[0].info_user.slag.filter(
                  (item) => item.id == element.id
                ).length > 0
              ) {
                bank_transfer =
                  !bank_transfer && element.urlTransfer
                    ? element.urlTransfer
                    : bank_transfer;
                purchase_credit_card =
                  !purchase_credit_card && element.urlCreditCard
                    ? element.urlCreditCard
                    : purchase_credit_card;
              }
            }
          );
        }
        if (!bank_transfer) {
          bank_transfer =
            this.listContent[0].sale_basic.column &&
            this.listContent[0].sale_basic.column[0].urlPageThankTransfer
              ? this.listContent[0].sale_basic.column[0].urlPageThankTransfer
              : this.saleSettingById.url_page_thanks_transfer;
        }
        if (!purchase_credit_card) {
          purchase_credit_card =
            this.listContent[0].sale_basic.column &&
            this.listContent[0].sale_basic.column[0].urlPageThankCredit
              ? this.listContent[0].sale_basic.column[0].urlPageThankCredit
              : this.saleSettingById.url_page_thanks_card;
        }
        if (this.$route.name.includes("paymentBuy")) {
          this.purchaseByUrl = true;
          if (this.$route.query && this.$route.query.urlSale === true) {
            this.urlSale = true;
          }
        }
        const data = {
          postal_code:
            this.infoBuyer.zipCodeOne + "" + this.infoBuyer.zipCodeTwo,
          building_room: this.infoBuyer.building_room,
          tel:
            this.infoBuyer.phone1 +
            "-" +
            this.infoBuyer.phone2 +
            "-" +
            this.infoBuyer.phone3,
          company_name: this.infoBuyer.company_name,
          company_type: this.infoBuyer.company_type,
          content_id: this.$route.params.id,
          lastName: this.infoBuyer.lastName,
          firstName: this.infoBuyer.firstName,
          lastNameKana: this.infoBuyer.lastNameKana,
          firstNameKana: this.infoBuyer.firstNameKana,
          email: this.infoBuyer.email,
          phone: this.infoBuyer.phone,
          address: this.infoBuyer.address,
          user_input: this.userInput,
          payment_method: this.payment.method,
          content_title: this.listContent[0].content_title,
          price_content: this.totalPrice,
          fee: this.fee,
          tax: this.tax,
          total_price: this.totalPricePayment,
          sale_format: this.listContent[0].sale_basic.column[0].sale_format * 1,
          token: this.token,
          cardId: this.cardId,
          cardType: this.cardType,
          cardNumber: this.cardNumber,
          cardExpirationDate: this.cardExpirationDate,
          interval: this.listContent[0].sale_basic.column[0].billInternal,
          intervalCount: this.listContent[0].sale_basic.column[0].addBill,
          trial_period: this.listContent[0].sale_basic.column[0].trialPeriod
            ? this.listContent[0].sale_basic.column[0].trialPeriod * 1
            : 0,
          initial_cost: this.listContent[0].sale_basic.column[0].initialCost
            ? this.listContent[0].sale_basic.column[0].initialCost * 1
            : 0,
          total_cycle: this.listContent[0].sale_basic.column[0].billingCount
            ? this.listContent[0].sale_basic.column[0].billingCount
            : 0,
          total_point_bonus: this.listContent[0].point_bonus.total_point_bonus,
          bank_transfer: bank_transfer,
          purchase_credit_card: purchase_credit_card,
          slag_id:
            this.listContent[0].sale_basic.column &&
            this.listContent[0].sale_basic.column[0].baseSlag
              ? this.listContent[0].sale_basic.column[0].baseSlag.map(function (
                  item
                ) {
                  return item.slag_id;
                })
              : [],
          content_id_supplies:
            this.listContent[0].sale_basic.column &&
            this.listContent[0].sale_basic.column[0].baseContent
              ? this.listContent[0].sale_basic.column[0].baseContent.map(
                  function (item) {
                    return item.value;
                  }
                )
              : [],
          info_subscription: this.infoSubription,
          keyPayPal: this.keyPayPal,
          purchaseByUrl: this.purchaseByUrl,
          urlSale: this.urlSale,
          purchase_location_url: this.purchase_location_url,
          idPage_purchase_location: this.purchase_location_url.includes(
            `/${Routes.PAGE}`
          )
            ? this.getIDPage()
            : 0,
        };
        this.$router.push({
          name: this.$route.params.shopId
            ? "confirm_payment"
            : "confirm_payment domain",
          params: {
            id: this.id,
          },
          query: {
            info: JSON.stringify(data),
          },
        });
      }
    },
    paymentMethod(value) {
      this.payment.method = value;
    },
    getAddress(postcode) {
      var postal_code = require("japan-postal-code");
      const _this = this;
      _this.infoBuyer.province = "";
      postal_code.get(postcode, function (address) {
        _this.infoBuyer.province = address.prefecture;
        _this.infoBuyer.address =
          address.prefecture + address.city + address.area;
      });
    },
    isZipCodeValid() {
      if (
        (this.infoBuyer.zipCodeTwo || this.infoBuyer.zipCodeOne) &&
        (!this.infoBuyer.prefecture ||
          !this.infoBuyer.city ||
          !this.infoBuyer.area)
      ) {
        return false;
      } else {
        return true;
      }
    },
    getIDPage() {
      var url = this.purchase_location_url;
      var parameUrl = url.split("/")[url.split("/").length - 1];
      var idPage = parameUrl.replace(`${Routes.PAGE}=`, "")[0];
      return idPage;
    },
  },
};
</script>
<style lang="scss">
.cart {
  background-color: #f5f5f5;
  min-height: calc(100vh - 280px);
  .cart-empty {
    width: 100%;
    min-height: calc(100vh - 280px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      background-color: #fe3834;
      color: #fff;
    }
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .checkout-btn {
    width: 100%;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
}
.checkout-screen {
  background-color: #f5f5f5;
  padding-bottom: 10px;
  .card {
    background: #7a95c6;
    color: white;
    justify-content: center;
    margin: auto;
  }
  #stripe-element-mount-point {
    border: 1px solid blue;
  }
  .checkout {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    .temp-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price-num {
        font-weight: 500;
      }
    }
    .prices-total {
      border-top: 1px solid #f2f2f2;
      .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-num {
          color: #fe3834;
          font-size: 28px;
          font-weight: 600;
          text-align: right;
          .vat {
            font-size: 16px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .custom-button-paypal {
    background: #ffc439;
    border-radius: 4px;
    width: 90%;
    margin: auto;
    &:hover {
      cursor: pointer;
      background: #fcb208;
    }
  }
  .checkout-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #635bff;
  }
  .cancel-btn {
    width: 30%;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #fe3834;
  }
  .showmore-btn {
    width: auto;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    background-color: #e19c9b;
  }
  .input-card {
    margin-left: 40px;
    .check-box-card {
      margin: 5px;
      margin-right: 10px;
    }
    .select-card {
      width: 240px;
    }
  }
}
.model-foote {
  margin-top: 10px;
}
.hover:hover {
  cursor: pointer;
}
.fd-admin-listproperty-card-filter {
  position: relative;
  label {
    display: inline;
    border: none;
  }
  button {
    padding: 0;
    padding-bottom: 4px;
    box-shadow: none !important;
  }
  .b-calendar-header {
    display: none;
  }
  .b-calendar-nav {
    border: 1px solid #d8dbe0;
    background-color: #ffffff;
    border-color: #768192 !important;
    border-radius: 0.25rem;
    .btn-outline-secondary {
      color: #6c778a !important;
    }
  }
  .b-calendar-nav:focus {
    border-color: #768192 !important;
    box-shadow: none;
  }
  .b-calendar-grid:focus {
    border-color: #768192 !important;
    box-shadow: none;
  }
  .dropdown-menu {
    padding: 0;
    border: none;
  }
  .b-calendar-inner {
    position: absolute;
    top: 30px;
  }
}
.custom-button-paypal {
  background: #ffc439;
  border-radius: 4px;
  width: 90%;
  margin: auto;
  &:hover {
    cursor: pointer;
    background: #fcb208;
  }
}
.form-control {
  color: #000;
}
.form-control:focus {
  color: #000;
}
.info-payment {
  display: flex;
  @media (max-width: 992px) {
    display: block;
  }
  .personal {
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .currency {
    width: 50%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}
.button-payment {
  &-desktop {
    @media (max-width: 992px) {
      display: none !important;
    }
  }
  &-mobile {
    @media (min-width: 993px) {
      display: none !important;
    }
  }
}
.text-terms:hover {
  cursor: pointer;
}

#purchase {
  @media (max-width: 575px) {
    padding: 0 10px;
  }
  clear: both;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
  background: #fff;
  .floatingWrap {
    padding: 30px 0px 60px;
    .row {
      // display: block !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .col-lg-12 {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .col-12 {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .col-lg-6:first-child {
      padding-left: 0 !important;
    }
    .row.policyContent {
      display: inline-block;
      width: 515px;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    .btnSubmit {
      width: 515px;
      margin: 30px 0 10px 0;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
  }
  .ttl01 {
    font-size: 20px;
    border-bottom: 1px solid #d1d2d4;
    margin: 5px 0 30px;
    font-weight: normal;
    span {
      display: inline-block;
      padding: 0 10px 5px;
      border-bottom: 1px solid #424b55;
      position: relative;
      bottom: -1px;
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .inputArea {
    .row {
      margin-top: 10px;
      .necessary {
        display: inline-block;
        background: #f55747;
        width: 40px;
        height: 20px;
        line-height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.2em;
        margin: 0 0 0 10px;
        position: relative;
        top: -2px;
        transition: 0.5s linear;
      }
      .necessary::after {
        content: "必須";
      }
    }
    div {
      .nameWrap {
        width: 50%;
        float: left;
      }
    }
  }
  .info-order {
    width: 515px;
    float: left;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  input.formParts {
    height: 50px;
    border: 1px solid #8f9298;
    padding: 0 10px;
    font-size: 16px;
    border-radius: 2px;
    width: 100%;
  }
  input.formParts[type="radio"] {
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    border: none;
    opacity: 0;
    font-size: 0;
    position: absolute;
  }
  section + section {
    margin: 20px 0 0;
  }
  .detail-order {
    width: 370px;
    float: right;
    @media (max-width: 992px) {
      float: none;
      width: 100%;
    }
  }
  button.btn01 {
    height: 70px;
    font-size: 20px;
    background: #97c71f;
    box-shadow: 0px 2px 0px 0px #759917;
  }
  button.formParts {
    font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
      Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    width: 100%;
    color: #fff;
    text-align: center;
    font-weight: bold;
    display: inline-block;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  .text-btn-purchase {
    font-size: 12px;
    margin-top: 20px;
    width: 515px;
    @media (max-width: 575px) {
      width: 100%;
    }
  }
  .erroTxt {
    clear: both;
    margin: 5px 0 0;
    color: #f86261;
    font-weight: bold;
  }
}
.floatingWrap {
  position: relative;
  width: 100%;
  min-height: 540px;
  section {
    .inner {
      margin: unset !important;
      width: 100%;
      padding: unset !important;
    }
  }
  .floatingBox {
    h2 {
      background: #424b55;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 1;
      padding: 12px 0;
      margin: 0px;
    }
    .sub {
      padding: 10px 20px 0;
      dl {
        // overflow: hidden;
        clear: both;
        dt {
          float: left;
          font-weight: 100;
        }
        dd {
          margin: 0 0 0 8em;
          font-weight: bold;
        }
      }
    }
    .excludingTax {
      font-size: 12px;
    }
    .total {
      padding: 15px;
      margin: 10px 0;
      background: #e6e8ea;
      // overflow: hidden;
      font-weight: bold;
      dt {
        float: left;
        font-weight: bold;
      }
      dd {
        float: right;
        text-align: right;
        color: #d45452;
        font-size: 16px;
      }
    }
    .notes {
      padding: 5px 10px 0;
      font-size: 12px;
      color: #666;
    }
  }
  .floatingBoxInner {
    border: 1px solid #bfc0c4;
    border-top: none;
    padding: 0 0 10px;
    pre {
      border-radius: 0;
      padding: 0;
      margin: 0;
      font-size: 14px;
      background: #fff;
      border: 0;
      line-height: 1.42857143;
      word-break: break-all;
      word-wrap: break-word;
      color: #333;
      display: block;
    }
  }
  .detail-order {
    position: sticky;
    top: 20px;
    @media (max-width: 992px) {
      position: relative;
    }
  }
}
.paymentContent {
  .tabBtn {
    // overflow: hidden;
    position: relative;
    z-index: 2;
    font-size: 0;
    text-align: center;
    list-style: none;
    li {
      font-size: 16px;
      background: #fff;
      display: block;
      float: left;
      input[type="radio"] + label {
        box-sizing: border-box;
        display: block;
        padding: 0 20px;
        span {
          display: inline-block;
          padding: 0 0 0 30px;
          height: 50px;
          line-height: 50px;
          background: url(../../assets/img/icon_radio01.png) no-repeat left
            center;
        }
      }
      input[type="radio"]:checked + label {
        span {
          background: url(../../assets/img/icon_radio02_on.png) no-repeat left
            center;
        }
      }
    }
  }
}
.companyType {
  input[type="radio"] {
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    border: none;
    opacity: 0;
    font-size: 0;
    position: absolute;
  }
  input[type="radio"] + label {
    box-sizing: border-box;
    display: block;
    padding: 0 20px;
    margin: 0;
    span {
      display: inline-block;
      padding: 0 0 0 30px;
      height: 50px;
      line-height: 50px;
      background: url(../../assets/img/icon_radio01.png) no-repeat left center;
    }
  }
  input[type="radio"]:checked + label {
    span {
      background: url(../../assets/img/icon_radio02_on.png) no-repeat left
        center;
    }
  }
}

.hide {
  display: none !important;
}
.purchase-policy {
  a {
    font-weight: bolder;
    text-decoration-line: underline !important;
    color: #000;
    text-decoration: none !important;
  }
}
.body-payment {
  margin: 0 auto;
  width: 980px;
  @media (max-width: 992px) {
    width: 720px;
  }
  @media (max-width: 767px) {
    width: 540px;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
}
.bread-drum {
  position: absolute;
  right: 0;
  top: 50%;
  @media (max-width: 575px) {
    right: auto;
    top: 100%;
    left: 10px;
  }
  img {
    @media (max-width: 767px) {
      width: 250px;
    }
  }
}
.footer .row:nth-child(2) ul.footer-list .footer-item {
  display: none;
}
.footer .margin-content {
  max-width: 980px !important;
}
.radio_type {
  margin-right: 5px;
  border: 0px;
  width: 100%;
  height: 100%;
}
</style>
